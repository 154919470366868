import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import inputStyles from '../Input/Input.module.css';
import styles from './Select.module.css';

interface Props extends FieldRenderProps<string, HTMLSelectElement> {
  className?: string;
  classNames?: Partial<Record<'chevron' | 'input', string>>;
  block?: boolean;
  label?: string;
}

/**
 * Select for use with FinalForm.
 * if FinalForm is not used, props for the input has to be passed through the "input" prop.
 *
 * Example usage:
 * <Field name={""} component={Select}>
 *   <option value="a">A value</option>
 *   <option value="b">B value</option>
 * </Field>
 *
 *
 * @param className "className"
 * @param label label
 * @param block render as a block-level element
 */

const Select: React.FC<Props> = ({
  className,
  classNames,
  block = true,
  input,
  label,
  meta,
  children,
  ...props
}) => {
  const hasError = meta.submitError || (meta.touched && meta.error);
  return (
    <label className={cx(inputStyles.inputRootNode, { [inputStyles.block]: block })}>
      {label && <span className={inputStyles.label}>{label}</span>}
      <select
        className={cx(
          inputStyles.root,
          { [inputStyles.block]: block, [inputStyles.error]: hasError },
          className,
          classNames?.input,
        )}
        {...input}
        {...props}
      >
        {children}
      </select>
      <span className={cx(styles.chevron, classNames?.chevron)} />
      {hasError && (
        <div className={inputStyles.errorMsg}>
          {meta.submitError} {meta.error}
        </div>
      )}
    </label>
  );
};

export default Select;
