import React from 'react';
import { DayPickerInputProps, DayPickerProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';
import moment from 'moment';

import CalendarIcon from '../../../icons/calendar_today.svg';
// If someone has the time change this fro Daypicker to DayPicker, filename changes are a pain
import styles from './Daypicker.module.css';

import 'moment/locale/nb.js';

interface Props extends FieldRenderProps<string | Date | undefined, HTMLInputElement> {
  className?: string;
  classNames?: Partial<Record<'icon' | 'inputWrapper', string>>;
  block?: boolean;
  label?: string;
  dayPickerInputProps?: DayPickerInputProps;
  dayPickerProps?: DayPickerProps;
  disabled?: boolean;
}

/**
 * DayPicker for use with FinalForm.
 * if FinalForm is not used, props for the input has to be passed through the "input" prop.
 *
 * Example usage:
 * <Field name={""} render={DayPicker}/>
 *
 *
 * @param className "className"
 * @param label label
 * @param block render as a block-level element
 */

const DayPicker: React.FC<Props> = ({
  className,
  classNames,
  input,
  label,
  meta,
  block = true,
  dayPickerInputProps,
  dayPickerProps,
  disabled,
}) => {
  const hasError = meta.submitError || (meta.touched && meta.error);
  return (
    <label className={cx({ [styles.block]: block })}>
      {label && <span className={styles.label}>{label}</span>}
      <div
        className={cx(
          styles.root,
          { [styles.error]: hasError, [styles.disabled]: disabled },
          className,
          classNames?.inputWrapper,
        )}
      >
        <DayPickerInput
          value={input.value}
          onDayChange={selectedDay => {
            input.onChange({
              target: {
                value: selectedDay,
              },
            });
          }}
          component={props => <input {...props} onChange={undefined} />}
          format="YYYY-MM-DD"
          formatDate={date => moment(date).format('YYYY-MM-DD')}
          dayPickerProps={{
            localeUtils: MomentLocaleUtils,
            locale: 'nb',
            ...dayPickerProps,
          }}
          {...dayPickerInputProps}
        />
        <span className={cx(styles.icon, classNames?.icon)}>
          <CalendarIcon />
        </span>
      </div>
      {hasError && (
        <div className={styles.errorMsg}>
          {meta.submitError} {meta.error}
        </div>
      )}
    </label>
  );
};

export default DayPicker;
