import React, { ReactNode, SVGAttributes } from 'react';
import cx from 'classnames';

import { PolymorphicComponent } from '../../../../types/generics';
import styles from './Pill.module.css';

type Colors = 'pink' | 'green' | 'offwhite' | 'error' | 'subdued' | 'lightgrey' | 'black';
type Size = 'small' | 'large';

export interface PillProps {
  children: ReactNode;
  className?: string;
  icon?: React.FC<SVGAttributes<ReactNode>>;
  size?: Size;
  color?: Colors;
  noMargin?: boolean;
  iconPlacement?: 'left' | 'right';
}

function Pill<T extends React.ElementType>({
  children,
  color = 'black',
  size = 'small',
  icon: Icon,
  iconPlacement = 'left',
  className,
  noMargin = false,
  component,
  ...rest
}: PolymorphicComponent<T, PillProps>) {
  const Component = component || 'div';
  return (
    <Component
      className={cx(
        styles.root,
        styles[color],
        {
          [styles.margin]: !noMargin,
          [styles.large]: size === 'large',
          [styles.hasIconLeft]: Icon && iconPlacement === 'left',
          [styles.hasIconRight]: Icon && iconPlacement === 'right',
        },
        className,
      )}
      {...rest}
    >
      {Icon && (
        <Icon fill="currentColor" className={cx(styles.icon, styles[`icon-${iconPlacement}`])} />
      )}
      <span>{children}</span>
    </Component>
  );
}

export default Pill;
