import React from 'react';
import cx from 'classnames';
import WithColor from 'hocs/WithColor';

import styles from './Typography.module.css';

export interface TypographyInterface {
  component?: React.ElementType;
  variant?:
    | 'heading'
    | 'hero'
    | 'smallheading'
    | 'blockquote'
    | 'subheading'
    | 'small'
    | 'supersmall'
    | 'strong'
    | 'faded'
    | 'title'
    | 'largetitle'
    | 'p'
    | 'md'
    | 'md-serif'
    | 'md-sans'
    | 'info';
  block?: boolean;
  className?: string;
  raw?: boolean;
  align?: 'inherit' | 'left' | 'right' | 'center';
}

/**
 * Renders a text with a variant as a <span> if nothing else is specificed.
 * The idea:
 * - Don't assume the document hierarchy, let the user set proper tags for SEO when applicable
 * - Use only one prop that infers the styling of the elemnent itself, the variant. Other
 *   props never alter the look of the text itself, just how it interacts with elements around it.
 * - All fontSizes are rems for easier resizing.
 *
 * @param component For SEO / extensability - Render the Typography variant into a Component or HTML-tag,
 * @param variant String with the name of the target style
 * @param color textColor via WithColor, default="inherit"
 * @param block render as a block-level element
 * @param className composable className
 * @param raw use if the text is standalone and does not need margin towards other Typography-elements
 * @param align Align the text. default="inherit"
 * @param children contents
 * @returns A functional component
 */

const Typography: React.FC<TypographyInterface> = ({
  component: Component = 'span',
  variant,
  children,
  block = false,
  raw = false,
  className,
  align,
  ...rest
}) => {
  const baseClass = variant ? styles[variant] : undefined;

  return (
    <Component
      className={cx(
        styles.root,
        baseClass,
        {
          [styles.raw]: raw,
          [styles.block]: block,
          [styles[`align-${align}`]]: align,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default WithColor(Typography, { color: 'inherit' });
